import { GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { jsonFileNameList } from "@sellernote/_shared/src/i18n";

import Main from "./Main";

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  if (!locale) {
    throw new Error("locale is required");
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, jsonFileNameList)),
    },
  };
};

export default function MainPage() {
  return <Main />;
}
